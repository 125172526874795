import {
  addMonths,
  addWeeks,
  differenceInDays,
  endOfMonth,
  startOfMonth,
  startOfWeek,
  subDays,
} from 'date-fns';
import moment from 'moment';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { actionTypes } from './actionTypes';

const defaultEarliestDate = new Date('2019-01-06');

const initialAuthState = {
  latestDateMonthly: startOfMonth(addMonths(new Date(), -2)).toISOString(),
  latestDateWeekly: startOfWeek(addWeeks(new Date(), -2)).toISOString(),
  earliestDateMonthly: startOfMonth(defaultEarliestDate).toISOString(),
  earliestDateWeekly: startOfWeek(defaultEarliestDate).toISOString(),
  latestDateDaily: subDays(new Date(), 1).toISOString(),
  earliestDateDaily: subDays(defaultEarliestDate, 1).toISOString(),
  dateRange: {
    startDate: startOfMonth(addMonths(new Date(), -1)),
    endDate: endOfMonth(addMonths(new Date(), -1)),
  },
  rangeType: 'Monthly',
  labelSelected: 'Last Month',
  isChangeRangeType: false,
  tempFixApplied: undefined,
};

export const reducer = persistReducer(
  {
    storage,
    key: 'date-range',
    whitelist: [
      'dateRange',
      'latestDateMonthly',
      'latestDateWeekly',
      'earliestDateMonthly',
      'earliestDateWeekly',
      'rangeType',
      'labelSelected',
      'tempFixApplied',
    ],
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.UpdateLatestDate: {
        const { latest_month, latest_week, earliest_month, earliest_week } =
          action.payload;
        if (latest_month && latest_week) {
          const mLatestMonthly = moment(latest_month);
          const mLatestWeekly = moment(latest_week);
          const mEarliestMonth = moment(earliest_month);
          const mEarliestWeek = moment(earliest_week);
          const latest_monthly_month = mLatestMonthly.format('MM');
          const latest_monthly_year = mLatestMonthly.format('YYYY');
          const latest_weekly_month = mLatestWeekly.format('MM');
          const latest_weekly_date = mLatestWeekly.format('DD');
          const latest_weekly_year = mLatestWeekly.format('YYYY');

          let monthly = new Date(
              +latest_monthly_year,
              +latest_monthly_month - 1,
              1,
            ),
            weekly = new Date(
              +latest_weekly_year,
              +latest_weekly_month - 1,
              +latest_weekly_date,
            );
          let dateRange = state.dateRange;
          const weekDiff = differenceInDays(
            dateRange.endDate,
            dateRange.startDate,
          );
          if (
            weekDiff < 7 &&
            !state.tempFixApplied &&
            state.rangeType.toLowerCase() === 'monthly'
          ) {
            dateRange = {
              startDate: startOfMonth(addMonths(new Date(), -1)),
              endDate: endOfMonth(addMonths(new Date(), -1)),
            };
          }
          return {
            ...state,
            dateRange,
            tempFixApplied: true,
            earliestDateMonthly: mEarliestMonth,
            earliestDateWeekly: mEarliestWeek,
            latestDateMonthly: monthly.toISOString(),
            latestDateWeekly: weekly.toISOString(),
          };
        }
        return state;
      }

      case actionTypes.SetDateRange: {
        const newDateRange = {
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        };
        return {
          ...state,
          dateRange: newDateRange,
        };
      }

      case actionTypes.SetRangeType: {
        return {
          ...state,
          rangeType: action.payload,
        };
      }

      case actionTypes.SetLabelSelected: {
        return {
          ...state,
          labelSelected: action.payload,
        };
      }

      case actionTypes.ChangeRangeType: {
        return {
          ...state,
          isChangeRangeType: action.payload,
        };
      }

      default:
        return state;
    }
  },
);
